import { asosTheme } from '@cof/plastic-components';
import { merge } from 'merge-anything';

import components from './componentStyles';
import globalStyles from './globalStyles';

export default merge({}, asosTheme, {
    components,
    globalStyles
});
