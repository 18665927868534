import {
    BRANDS,
    cookieBannerPolicyLinks as plasticCookieBannerPolicyLinks,
    CookieBanner
} from '@cof/plastic-components';
import React from 'react';

const TransformedCookieBanner = (props) => {
    const policyLinks = Object.entries(plasticCookieBannerPolicyLinks).reduce(
        (updatedPolicyLinks, [brand, link]) => ({
            ...updatedPolicyLinks,
            [brand === BRANDS.CAPITAL_ONE ? 'capitalOne' : brand.toLowerCase()]: link
        }),
        {}
    );
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <CookieBanner allowRejectAll policyLinks={policyLinks} {...props} />;
};

export default TransformedCookieBanner;
