/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
    BrandedLogo,
    CapitalOneLogoPrimaryLight,
    LittlewoodsLogoPrimary,
    LumaLogoPrimaryDark,
    OceanLogoPrimary,
    VeryLogoPrimary,
    PostOfficeLogoCore,
    ASOSLogoPrimary
} from '@cof/plastic-components';
import ThinkMoneyCreditCardLogo from './ThinkMoney-CreditCard.svg?react';

const ThinkMoneyCreditCardLogoWithAlt = () => <ThinkMoneyCreditCardLogo aria-label="thinkmoney Credit Card" />;

const brandConfig = {
    capitalOne: CapitalOneLogoPrimaryLight,
    littlewoods: LittlewoodsLogoPrimary,
    luma: LumaLogoPrimaryDark,
    ocean: OceanLogoPrimary,
    very: VeryLogoPrimary,
    postoffice: PostOfficeLogoCore,
    asos: ASOSLogoPrimary,
    thinkmoney: ThinkMoneyCreditCardLogoWithAlt
};

// pass null for the allowList as this will cause it to pick up the allowList from the top level brand provider
const Logo = (props) => <BrandedLogo brandConfig={brandConfig} allowList={null} {...props} />;

export default Logo;
