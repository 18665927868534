import { ERROR_TYPE } from '../../utilities/constants';
import capitalOne from './capitalOne';

/* eslint-disable max-len */
const ExternalLinks = {
    QUICKCHECK: 'https://apply-creditcard.thinkmoney.co.uk/'
};

const content = {
    DocumentHead: {
        Title: 'Credit Card Application - thinkmoney',
        Description: 'Complete our application form for a thinkmoney credit card. Response in 60 seconds.'
    },
    TechnicalError: {
        [ERROR_TYPE.SERVER_ERROR]: {
            ...capitalOne.TechnicalError[ERROR_TYPE.SERVER_ERROR],
            Button: {
                Text: 'Do another QuickCheck',
                URL: ExternalLinks.QUICKCHECK
            }
        }
    },
    UnifiedDateForm: {
        ContinueButton: 'CONTINUE'
    }
};

export default {
    ...capitalOne,
    ...content
};
