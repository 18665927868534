/* eslint-disable arrow-body-style */
import { useState, useEffect } from 'react';

import axios from 'axios';
import getConfig from './config';

const applyArgToAxiosConfig = (arg, config) => {
    let resultConfig = typeof config === 'function' ? config(arg) : config;
    if (typeof resultConfig === 'string') {
        resultConfig = { url: resultConfig };
    }

    return resultConfig;
};

const useRequestCallback = (axiosConfigOrFactory, axiosConfigOverrides = {}) => {
    const [state, setState] = useState({
        execCount: 0,
        execInput: null,
        loading: false,
        error: null,
        response: null,
        data: null
    });

    const exec = (arg) =>
        setState({
            ...state,
            loading: true,
            error: null,
            execInput: arg,
            execCount: state.execCount + 1
        });

    useEffect(() => {
        if (!state.execCount) {
            return;
        }

        async function doRequest() {
            const requestConfig = {
                ...applyArgToAxiosConfig(state.execInput, axiosConfigOrFactory),
                ...axiosConfigOverrides
            };

            try {
                const response = await axios(requestConfig);
                setState({ ...state, loading: false, response, data: response.data });
            } catch (error) {
                setState({ ...state, loading: false, error });
            }
        }

        doRequest();
        // eslint-disable-next-line
    }, [state.execCount]);

    const retry = () => exec(state.execInput);
    const { loading, error, response, data, execCount, execInput } = state;

    return [exec, loading, { error, retry, response, data, execCount, input: execInput }];
};

export const usePostCallback = (axiosConfigOrFactory) => useRequestCallback(axiosConfigOrFactory, { method: 'POST' });

export const useVerifySubmit = () => {
    return usePostCallback((data) => {
        return { ...getConfig('elevate'), data };
    });
};
