import axios from 'axios';
import { useState, useEffect } from 'react';
import { useBrand } from '@cof/plastic-components';
import useConfig from '../useConfig/useConfig';

import { addNewRelicError } from '../newRelic';

const fetchFirstName = async ({ baseURL, quotation, token, brand, channel }) => {
    if (!quotation && !token) return '';
    const queryObject = { quotation, token, channel, partner: brand };
    Object.keys(queryObject).forEach((key) => queryObject[key] === undefined && delete queryObject[key]);
    const queryParams = new URLSearchParams(queryObject);

    try {
        const res = await axios.get(`${baseURL}?${queryParams.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const returnedFirstName = res?.data?.firstName;
        if (returnedFirstName) {
            return returnedFirstName;
        }
        return '';
    } catch (_) {
        addNewRelicError('axios-error', { clientTimestamp: Date.now(), sawError: true });
        return '';
    }
};

const useCustomerEndpoint = (configURL, quotation, token, channel) => {
    const [name, setName] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const brand = useBrand();

    const {
        values: [baseURL]
    } = useConfig([configURL]);

    useEffect(() => {
        fetchFirstName({ baseURL, quotation, token, brand, channel })
            .then(setName)
            .finally(() => {
                setIsLoading(false);
            });
    }, [baseURL, quotation, token, brand, channel]);

    return { name, isLoading };
};

export default useCustomerEndpoint;
