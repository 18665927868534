export const EVENTS = {
    VERIFY_NEW_SESSION: 'verifyNewSession',
    VERIFY_KBA_PASS: 'verifyKBAPass',
    VERIFY_KBA_FAIL: 'verifyKBAFail',
    GDPR_PREF_ALLOWS_2: 'GDPR Pref Allows 2',
    GDPR_PREF_ALLOWS_3: 'GDPR Pref Allows 3',
    GDPR_PREF_ALLOWS_2_3: 'GDPR Pref Allows 2,3',
    KBA_VALIDATION_FAILED: 'kbaValidationFailed'
};

const pushToDataLayer = (values) => {
    if (!Object.values(EVENTS).includes(values.event)) {
        throw new Error('Unknown event');
    }
    if (window.dataLayer) {
        window.dataLayer.push(values);
    }
};

export default pushToDataLayer;
