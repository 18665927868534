import { oceanTheme } from '@cof/plastic-components';
import { merge } from 'merge-anything';

import components from './componentStyles';
import globalStyles from './globalStyles';
import componentColors from './componentColors';

export default merge({}, oceanTheme, {
    components,
    componentColors,
    globalStyles
});
