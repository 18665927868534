import { THEME_CONSTANTS } from '@cof/plastic-components';
import { css } from 'styled-components';
import cssSystem from '@styled-system/css';

const getColors = (theme) => theme.components.verify;

const { COLORS: CN } = THEME_CONSTANTS;

export default {
    mainHeading: CN.luma.purple500,
    background: CN.global.grey50,
    border: '2px solid',
    borderColor: CN.luma.purple500,
    heading: CN.luma.purple500,
    footerVariant: 'lightSecondary',
    errorPanel: CN.luma.red550,
    appHeader: ({ theme }) => css`
        ${cssSystem({
            backgroundColor: getColors(theme).background,
            borderBottom: '0px'
        })}
    `
};
