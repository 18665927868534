import createStore from '@cof/acq-text-ukweb';
import withTemplate from '@cof/acq-text-ukweb/lib/plugins/hocs/withTemplate';

const { Text, withText, TextStore } = createStore({
    hocs: {
        TemplateText: withTemplate
    }
});

export { Text, withText, TextStore };
