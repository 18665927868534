import config from '@cof/ukweb-config/lib/clientConfig';
import { useEffect } from 'react';

window.initializeZuko = () => {
    const zukoConfig = config.get('zuko');
    if (zukoConfig.isEnabled && window.Zuko && window.zukoAttributes && !window.zukoInstance) {
        const zukoAttr = window.zukoAttributes;
        const slug = zukoConfig.slug[zukoAttr.brand] ?? zukoConfig.slug.default;

        window.zukoInstance = window.Zuko.trackForm({ slug, target: document.body })
            .setAttribute('Channel', zukoAttr.channel)
            .setAttribute('Brand', zukoAttr.brand)
            .setAttribute('Vendor', zukoAttr.vendor)
            .trackEvent(window.Zuko.FORM_VIEW_EVENT);
    }
};

export const trackGenericEvent = (eventName) => {
    if (typeof window?.zukoInstance?.trackEvent === 'function') {
        try {
            window.zukoInstance.trackEvent(eventName);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }
};

// eslint-disable-next-line import/prefer-default-export
export const useZuko = ({ channel, brand, vendor }) => {
    useEffect(() => {
        // Validate the required attributes exist.
        if (channel && brand) {
            window.zukoAttributes = {
                channel,
                brand,
                vendor
            };
        }
        // Also called in GTM Zuko tag
        window.initializeZuko();
    }, [channel, brand, vendor]);

    const trackCompletionEvent = () => {
        if (window?.Zuko?.COMPLETION_EVENT) {
            trackGenericEvent(window.Zuko.COMPLETION_EVENT);
        }
    };

    const trackIncorrectKBA = () => {
        trackGenericEvent({ type: 'Error: Incorrect Date of Birth' });
    };

    const trackDoubleToken = () => {
        trackGenericEvent({ type: 'Error: Quotation and Short token provided' });
    };

    return { trackCompletionEvent, trackIncorrectKBA, trackDoubleToken };
};
