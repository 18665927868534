import { ALLOWED_BRANDS } from '../../utilities/constants';

const useBrandStrategy = () => {
    const { search, hostname } = window.location;

    if (hostname === '127.0.0.1' || hostname === 'localhost') {
        const query = new URLSearchParams(search);
        return query.get('partner');
    }

    if (hostname.indexOf('-') > -1) {
        const brand = hostname.split('-')[0];
        return ALLOWED_BRANDS.includes(brand) ? brand : null;
    }

    return null;
};

export default useBrandStrategy;
