/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import {
    AlertIcon,
    Box,
    Button,
    Flex,
    LoadingSpinnerBranded,
    PageRowFullHeight,
    Paragraph,
    THEME_CONSTANTS as TC,
    useBrand
} from '@cof/plastic-components';
import config from '@cof/ukweb-config/lib/clientConfig';

import { styled, css, useTheme } from 'styled-components';
import { color, compose, space, typography } from 'styled-system';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { v4 as uuid4 } from 'uuid';

import { zodResolver } from '@hookform/resolvers/zod';
import { DateForm } from '../../components/DateForm/DateForm';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer/dataLayer';
import { getChannel, getSearchParameter, getVendorCodeFromUrl, getFormattedDate, formSchema } from '../../utils';
import { withText } from '../../text/textStore';
import { useVerifySubmit } from '../../data';
import { REASON } from '../../utilities/constants';
import TechnicalError from '../TechnicalError';
import { useZuko } from '../../utilities/useZuko';
import { addNewRelicPageAction, setNewRelicAttribute } from '../../utilities/newRelic';

export const FocusableHeading = styled.h1
    .withConfig({
        shouldForwardProp: (prop) => !['textAlign'].includes(prop)
    })
    .attrs({
        tabIndex: '-1'
    })`
    ${({ theme, size }) => css`
        ${theme.components.heading.base}
        ${theme.components.heading[size]}
    `}
    outline: none;
    ${compose(color, typography, space)}
`;

FocusableHeading.displayName = 'FocusableHeading';

export const ErrorPanel = styled(Box)`
    color: ${({ theme }) => theme.components.verify.errorPanel};
    border: 2px solid currentColor;
    text-align: left;
`;

ErrorPanel.displayName = 'ErrorPanel';

export const ErrorText = styled(Button).attrs({
    variant: 'textLinkInline',
    role: 'link'
})`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    color: inherit;
    text-decoration: underline;
`;

ErrorText.displayName = 'ErrorText';

export const InlineIcon = styled(AlertIcon)`
    vertical-align: text-bottom;
`;

InlineIcon.displayName = 'InlineIcon';

const Verify = ({ Text }) => {
    // Setup react form
    const methods = useForm({ resolver: zodResolver(formSchema) });

    // get config
    const gtm = config.get('gtm');
    const { isEnabled: partnerAgreementEnabled } = config.get('partnerAgreement');

    // theme and brand setup
    const theme = useTheme();
    const brand = useBrand();

    // get url params
    const quotation = getSearchParameter('quotation');
    const vendor = getVendorCodeFromUrl();
    const channel = getChannel();
    const token = getSearchParameter('token');

    useEffect(() => {
        if (brand) setNewRelicAttribute('brand', brand);
        if (vendor) setNewRelicAttribute('vendorCode', vendor);
        if (channel) setNewRelicAttribute('channel', channel);
        setNewRelicAttribute('uniqueNRId', uuid4()); // unique ID for new relic for this page view
    }, [brand, vendor, channel]);

    const { capitalOne: capitalOneAgreement, partner: partnerAgreement } = config.get('agreementUrl');
    let agreementUrl = capitalOneAgreement;
    if (partnerAgreementEnabled) {
        agreementUrl = brand === 'capitalOne' ? capitalOneAgreement : partnerAgreement.replace('{partner}', brand);
    }

    const { trackIncorrectKBA, trackCompletionEvent, trackDoubleToken } = useZuko({
        channel,
        brand,
        vendor
    });

    const [submitVerify, isSubmitting, { response, error }] = useVerifySubmit();

    const dayFieldRef = React.useRef();
    const formErrorRef = React.useRef();

    const onSubmit = (values) => {
        submitVerify({
            quotation,
            token,
            channel,
            answers: {
                dateOfBirth: getFormattedDate(values.dateOfBirth)
            }
        });
    };

    useEffect(() => {
        if (gtm.isEnabled) {
            pushToDataLayer({
                event: EVENTS.VERIFY_NEW_SESSION,
                channel
            });
        }
    }, [channel, gtm.isEnabled]);

    useEffect(() => {
        // if a reason was returned from submission, focus the form error panel
        if (response?.data?.reason) {
            formErrorRef.current?.focus();
            if (gtm.isEnabled) {
                pushToDataLayer({
                    event: EVENTS.VERIFY_KBA_FAIL,
                    channel
                });
            }

            addNewRelicPageAction('submit-validation-error', { error: 'Incorrect KBA' });
            trackIncorrectKBA();
        }

        // if a token was returned from submission, redirect to agreement url
        if (response?.data?.token) {
            if (gtm.isEnabled) {
                pushToDataLayer({
                    event: EVENTS.VERIFY_KBA_PASS,
                    channel
                });
            }

            addNewRelicPageAction('Verify Completed');
            trackCompletionEvent();

            const qs = new URLSearchParams(window.location.search);
            qs.set('quotation', response.data.token);
            qs.delete('token');
            window.location.assign(`${agreementUrl}/?${qs.toString()}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, channel, gtm.isEnabled, agreementUrl]);

    if (error) {
        return <TechnicalError />;
    }

    if (quotation !== undefined && token !== undefined) {
        trackDoubleToken();
        return <TechnicalError />;
    }

    if (response?.data?.reason === REASON.TIMEOUT) {
        return <TechnicalError />;
    }

    const parsedIsSubmitting = isSubmitting || response?.data?.token != null;
    const responseReason = response?.data?.reason;
    const hasFormError = responseReason === REASON.INCORRECT || responseReason === REASON.EXPIRED;
    return (
        <PageRowFullHeight wrapperBg={theme.components.verify.background}>
            <Flex>
                <Box
                    bg={TC.COLORS.global.white}
                    border={theme.components.verify.border}
                    borderColor={theme.components.verify.borderColor}
                    mx="auto"
                    py={['md', 'md', 'lg', 'xl']}
                    px={['sm', 'md', 'lg', 'xl']}
                >
                    <FocusableHeading
                        size="large"
                        mb={['sm', 'md', 'lg']}
                        p="0"
                        color={theme.components.verify.mainHeading}
                        textAlign="center"
                    >
                        <Text path="Heading" />
                    </FocusableHeading>

                    <Paragraph py="0" my="sm" size="large">
                        <Text path="Paragraph1" />
                    </Paragraph>
                    <FormProvider {...methods}>
                        {!isSubmitting && hasFormError && (
                            <ErrorPanel tabIndex="-1" ref={formErrorRef} data-qa-id="form-error-panel" mb="sm" p="sm">
                                <ErrorText
                                    id="form-error-text"
                                    data-qa-id="form-error-text"
                                    onClick={() => {
                                        methods.setFocus('dateOfBirth');
                                    }}
                                >
                                    <Text path="IncorrectDateOfBirth" />
                                    <InlineIcon ml="xs" size="16px" mb={['2px', null, '5px']} />
                                </ErrorText>
                            </ErrorPanel>
                        )}
                        <Box>
                            <LoadingSpinnerBranded
                                backgroundColor={TC.COLORS.global.transparent}
                                data-qa-id="loading-spinner-dob"
                                active={parsedIsSubmitting}
                            />
                        </Box>
                        {!parsedIsSubmitting && <DateForm Text={Text} onSubmit={onSubmit} ref={dayFieldRef} />}
                    </FormProvider>
                </Box>
            </Flex>
        </PageRowFullHeight>
    );
};

Verify.propTypes = {
    Text: PropTypes.func.isRequired
};

const VerifyWithText = withText('Verify')(Verify);

VerifyWithText.displayName = 'Verify';

export default VerifyWithText;
