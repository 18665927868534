const loadPixel = (pixel) =>
    new Promise((resolve) => {
        if (pixel.complete) {
            resolve();
        } else {
            pixel.addEventListener('load', () => resolve(), { once: true });
            pixel.addEventListener('error', () => resolve(), { once: true });
        }
    });

export default loadPixel;
