import { DateInput, Flex, Button, Box } from '@cof/plastic-components';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { addNewRelicPageAction } from '../../utilities/newRelic';

const formatDateError = (errors, Text) => {
    if (errors && Object.keys(errors).length === 0) {
        return null;
    }

    const errorFields = [];
    Object.keys(errors.dateOfBirth).forEach((key) => {
        if (errors.dateOfBirth[key].type === 'too_small') {
            errorFields.push(key);
        }
    });

    if (errorFields.length > 0) {
        return { message: <Text path="DateField.Errors.Required" />, invalidFields: errorFields };
    }

    if (errors.dateOfBirth?.day?.message) {
        errorFields.push('day');
    }
    if (errors.dateOfBirth?.month?.message) {
        errorFields.push('month');
    }
    if (errors.dateOfBirth?.year?.message || errors.dateOfBirth?.message) {
        errorFields.push('year');
    }

    return { message: <Text path="DateField.Errors.Invalid" />, invalidFields: errorFields };
};

export const DateForm = React.forwardRef(({ Text, onSubmit }, ref) => {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useFormContext();

    return (
        <form data-qa-id="verify-form" method="post" onSubmit={handleSubmit(onSubmit)}>
            <Box margin="auto">
                <Controller
                    render={({ field }) => (
                        <DateInput
                            label={<Text path="DateField.Label" />}
                            ref={ref}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            error={formatDateError(errors, Text)}
                        />
                    )}
                    name="dateOfBirth"
                    control={control}
                    defaultValue={{ day: '', month: '', year: '' }}
                />
            </Box>
            <Flex flexDirection="column">
                <Button
                    data-qa-id="submit-dob-button"
                    mt="md"
                    mx="auto"
                    type="submit"
                    onClick={() => {
                        addNewRelicPageAction('Submit button clicked');
                    }}
                >
                    <Text path="Button" />
                </Button>
            </Flex>
        </form>
    );
});

DateForm.propTypes = {
    Text: PropTypes.any,
    onSubmit: PropTypes.any
};

DateForm.defaultProps = {
    Text: PropTypes.any,
    onSubmit: PropTypes.any
};

export default DateForm;