import React from 'react';
import { BRANDS, BrandedFooter, footerContent as plasticFooterContent } from '@cof/plastic-components';
import { useTheme } from 'styled-components';
import addSParameterToLinkUrls from '../../utilities/addSParameterToLinkUrls';

const Footer = () => {
    const theme = useTheme();
    const footerContent = Object.entries(plasticFooterContent).reduce(
        (updatedFooter, [footerBrand, content]) => ({
            ...updatedFooter,
            [footerBrand === BRANDS.CAPITAL_ONE ? 'capitalOne' : footerBrand.toLowerCase()]: {
                links: addSParameterToLinkUrls({ links: content.links }),
                copyright: content.copyright
            }
        }),
        {}
    );
    return (
        <BrandedFooter
            allowList={null}
            footerContent={footerContent}
            variant={theme.components.verify?.footerVariant}
        />
    );
};

export default Footer;
