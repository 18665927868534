import { useEffect, useState } from 'react';
import config from '@cof/ukweb-config/lib/clientConfig';

const getValues = (paths, allConfig) =>
    paths.map((path) => {
        let curr = allConfig;

        // eslint-disable-next-line no-restricted-syntax
        for (const key of path.split('.')) {
            curr = curr[key];
            if (typeof curr === 'undefined') {
                throw new Error(`Property ${key} is missing from config. Requested path: ${path}`);
            }
        }
        return curr;
    });

const useConfig = (paths) => {
    const allConfig = config.get();
    const [values, setValues] = useState(getValues(paths, allConfig));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setValues(getValues(paths, window.config));
        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { isLoading, values };
};

export default useConfig;
