import { oceanTheme, THEME_CONSTANTS as TC } from '@cof/plastic-components';

const componentColors = {
    ...oceanTheme.componentColors,
    forms: {
        input: {
            unified: {
                border: {
                    default: TC.COLORS.ocean.blue800
                }
            }
        }
    }
};

export default componentColors;
